import { ApiNames, ApiProxy, ApiRequest, HttpMethod } from '@lib/api/apiProxy';
import { IUserProfile } from '@lib/types/user.types';

export class UsersCommandApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.Users;
  private static apiVehicleName = ApiNames.Vehicles;

  // profile
  public static updateUser(
    userId: number,
    newValues: UpdateUser
  ): Promise<IUserProfile> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, `/${userId}`, HttpMethod.PUT, newValues)
    );
  }

  public static switchUserEmail(
    userId: number,
    email: string
  ): Promise<SwitchUserPreCallResult> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, `/${userId}/email`, HttpMethod.PUT, {
        email,
      })
    );
  }

  public static confirmUserEmailSwitch(
    userId: number,
    email: string
  ): Promise<SwitchUserResult> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/${userId}/email?confirm=true`,
        HttpMethod.PUT,
        {
          email,
        }
      )
    );
  }

  public static setDisabled(
    userId: number,
    disabled: boolean
  ): Promise<DisableUserResult> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/${userId}/disableAccount`,
        HttpMethod.PUT,
        {
          disabled,
        }
      )
    );
  }

  // appearance
  public static updateUserAppearance(
    userId: number,
    data: UpdateUserAppearance
  ): Promise<UserAppearance> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/${userId}/appearance`,
        HttpMethod.PUT,
        data
      )
    );
  }

  public static createUserAppearance(
    userId: number,
    data: CreateUserAppearance
  ): Promise<UserAppearance> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/${userId}/appearance`,
        HttpMethod.POST,
        data
      )
    );
  }

  // skills
  public static createUserSkills(
    userId: number,
    data: CreateUserSkill[]
  ): Promise<UserSkill[]> {
    const endpoint = `/${userId}/skills?is_new_entity=true`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, data)
    );
  }

  public static deleteUserSkills(skillIds: number[]): Promise<UserSkill[]> {
    const newSkillsString = skillIds.join(',');
    const endpoint = `/skills?newSkillIds=[${newSkillsString}]`;

    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE)
    );
  }

  public static updateUserSkill(
    skillId: number,
    attachments: { url: string; name: string }[]
  ): Promise<UserSkill[]> {
    const endpoint = `/${skillId}/skills`;

    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, {
        attachments,
        is_new_entity: true,
      })
    );
  }

  // props
  public static createUserProps(
    userId: number,
    data: CreateUserProp[] | CreateUserUniform[]
  ): Promise<UserProp[]> {
    const endpoint = `/${userId}/props`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, data)
    );
  }

  public static deleteUserProps(propIds: number[]): Promise<UserProp[]> {
    const endpoint = `/props?propIds=[${propIds.join(',')}]`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE)
    );
  }

  // vehicles
  public static createUserVehicles(
    userId: number,
    data: CreateUserVehicle[]
  ): Promise<UserVehicle> {
    const endpoint = `/${userId}/vehicles`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, data)
    );
  }

  public static updateUserVehicles(
    vehicle_id: number,
    data: Omit<UpdateUserVehicle, 'id'>
  ): Promise<UserVehicle> {
    const endpoint = `/vehicles/${vehicle_id}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiVehicleName, endpoint, HttpMethod.PUT, data)
    );
  }

  public static deleteUserVehicles(ids: number[]): Promise<number[]> {
    const endpoint = `/vehicles?ids=[${ids}]`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiVehicleName, endpoint, HttpMethod.DELETE)
    );
  }

  // user reviews
  public static createUserReview(
    data: UserReviewCreateDto
  ): Promise<BaseUserReview> {
    const endpoint = `/reviews`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, data)
    );
  }

  public static updateUserReview(
    reviewId: number,
    data: UserReviewUpdateDto
  ): Promise<BaseUserReview> {
    const endpoint = `/${reviewId}/reviews`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, data)
    );
  }

  // Admin
  public static adminUpdatePassword(
    userId: number,
    data: UpdateUserPassword
  ): Promise<boolean> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/${userId}/resetPassword`,
        HttpMethod.PUT,
        data
      )
    );
  }

  public static validateUserEmail(email: string): Promise<boolean> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, `/email?email=${email}`, HttpMethod.GET)
    );
  }

  public static upsertUserEmploymentVerification(
    userId: number,
    data: UpsertEmploymentVerification
  ): Promise<EmploymentVerification>;
  public static upsertUserEmploymentVerification(
    userId: number,
    data: UpsertUkEmploymentVerification
  ): Promise<UkEmploymentVerification> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/${userId}/employmentVerification`,
        HttpMethod.POST,
        data
      )
    );
  }

  public static updateUserLicenseTypes(
    userId: number,
    data: UpdateUserLicenseTypes
  ): Promise<LicenseType[]> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/${userId}/license_types`,
        HttpMethod.PUT,
        data
      )
    );
  }

  public static upsertUserBackgroundCheck(
    userId: number,
    data: UpsertUkBackgroundCheck
  ): Promise<UkBackgroundCheck> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/${userId}/backgroundChecks`,
        HttpMethod.POST,
        data
      )
    );
  }
}
