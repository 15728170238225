export const MessageCross = ({
  width = 15,
  height = 14,
  stroke = '#757575',
  className = '',
}: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={'message-cross-icon ' + className}
    >
      <path
        d='M6.254 11.8627C6.65667 11.94 7.07267 11.982 7.5 11.982C10.8013 11.982 13.5 9.55333 13.5 6.52733C13.5 3.50133 10.8013 1.07333 7.5 1.07333C4.19867 1.07333 1.5 3.50133 1.5 6.52733C1.5 8.316 2.44667 9.89067 3.89933 10.884C3.89867 11.4533 3.9 12.2213 3.9 13.0273L6.25333 11.86M5.83333 4.93333L9.16667 8.26667M9.16667 4.93333L5.83333 8.26667'
        stroke={stroke}
        strokeWidth='1.1'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
