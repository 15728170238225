export const PlusIcon = ({
  width = 12,
  height = 13,
  stroke = '#202020',
}: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.875 6.75H10.125M6 2.625V10.875'
        stroke={stroke}
        stroke-width='0.9'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
