export const GENDER_OPTIONS = [
  'Male',
  'Female',
  'Non-Binary',
  'Transgender Male',
  'Transgender Female',
];
export const GENDER_MAPPING = {
  Male: 'M',
  Female: 'F',
  'Non-Binary': 'NB',
  'Transgender Male': 'TM',
  'Transgender Female': 'TF',
};

export const GENDER_LABEL_MAPPER = {
  F: 'Female',
  TF: 'Trans Female',
  M: 'Male',
  TM: 'Trans Male',
  NB: 'Non-Binary',
};

// GENDER_SORT_ORDER_MAP also exists on casting-galleries service
export const GENDER_SORT_ORDER_MAP = {
  Male: 1,
  'Transgender Male': 2,
  'Non-Binary': 3,
  'Transgender Female': 4,
  Female: 5,
};
