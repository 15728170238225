import { useClientEnvironmentContext } from '@context/ClientEnvironmentContext/ClientEnvironmentContext';
import styled from 'styled-components';

export type LogoProps = {
  style?: React.CSSProperties;
  onClick?: () => void;
  fontSize?: number;
};

const INITIAL_FONT_SIZE = 24;

const LogoText = styled.span`
  color: ${({ theme }) => theme.palette.primary[0]};
  font-family: 'Montserrat', sans-serif;
  font-weight: ${({ theme }) => theme.palette.textFontWeight[0]};
`;

const SaaSLogo = ({
  fontSize = INITIAL_FONT_SIZE,
  onClick,
  style,
}: LogoProps): JSX.Element => {
  const { clientName, clientLogoUrl } = useClientEnvironmentContext();

  const logoStyle: React.CSSProperties = {
    fontSize,
    cursor: onClick ? 'pointer' : 'auto',
    textDecoration: 'none',
    maxWidth: 300,
    maxHeight: 48,
    ...style,
  };
  const logoTextStyle: React.CSSProperties = {
    fontSize,
    cursor: onClick ? 'pointer' : 'auto',
    textDecoration: 'none',
    maxWidth: 300,
    ...style,
  };

  return clientLogoUrl ? (
    <img src={clientLogoUrl} style={logoStyle} />
  ) : (
    <LogoText style={logoTextStyle}>{clientName}</LogoText>
  );
};

export default SaaSLogo;
