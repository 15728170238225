import type { SVGProps } from 'react';

export const USFlag = ({
  width = 24,
  height = 16,
  className = '',
  stroke = 'currentColor',
  strokeWidth = 1.5,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox='0 0 24 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-testid='us-flag'
    >
      <path d='M0 0H24V16H0V0Z' fill='white' />
      <path
        d='M0 0H24V1.23041H0V0ZM0 2.46082H24V3.69123H0V2.46082ZM0 4.92164H24V6.15205H0V4.92164ZM0 7.38246H24V8.61287H0V7.38246ZM0 9.84795H24V11.0784H0V9.84795ZM0 12.3088H24V13.5392H0V12.3088ZM0 14.7696H24V16H0V14.7696Z'
        fill='#D80027'
      />
      <path d='M0 0H12V8.61287H0V0Z' fill='#2E52B2' />
      <path
        d='M2.23611 6.49821L2.04898 5.89938L1.84313 6.49821H1.22559L1.72617 6.85844L1.53904 7.45727L2.04898 7.08768L2.54488 7.45727L2.35307 6.85844L2.86301 6.49821H2.23611ZM4.87003 6.49821L4.67822 5.89938L4.48173 6.49821H3.86418L4.36477 6.85844L4.17763 7.45727L4.67822 7.08768L5.18348 7.45727L4.99635 6.85844L5.49693 6.49821H4.87003ZM7.51331 6.49821L7.31214 5.89938L7.125 6.49821H6.49342L7.00804 6.85844L6.81155 7.45727L7.31214 7.08768L7.82676 7.45727L7.63026 6.85844L8.13085 6.49821H7.51331ZM10.1425 6.49821L9.95541 5.89938L9.75892 6.49821H9.1367L9.64196 6.85844L9.45483 7.45727L9.95541 7.08768L10.4607 7.45727L10.2595 6.85844L10.7741 6.49821H10.1425ZM4.67822 3.52277L4.48173 4.1216H3.86418L4.36477 4.49119L4.17763 5.08066L4.67822 4.71575L5.18348 5.08066L4.99635 4.49119L5.49693 4.1216H4.87003L4.67822 3.52277ZM2.04898 3.52277L1.84313 4.1216H1.22559L1.72617 4.49119L1.53904 5.08066L2.04898 4.71575L2.54488 5.08066L2.35307 4.49119L2.86301 4.1216H2.23611L2.04898 3.52277ZM7.31214 3.52277L7.125 4.1216H6.49342L7.00804 4.49119L6.81155 5.08066L7.31214 4.71575L7.82676 5.08066L7.63026 4.49119L8.13085 4.1216H7.51331L7.31214 3.52277ZM9.95541 3.52277L9.75892 4.1216H9.1367L9.64196 4.49119L9.45483 5.08066L9.95541 4.71575L10.4607 5.08066L10.2595 4.49119L10.7741 4.1216H10.1425L9.95541 3.52277ZM2.04898 1.15552L1.84313 1.74499H1.22559L1.72617 2.11458L1.53904 2.70873L2.04898 2.33914L2.54488 2.70873L2.35307 2.11458L2.86301 1.74499H2.23611L2.04898 1.15552ZM4.67822 1.15552L4.48173 1.74499H3.86418L4.36477 2.11458L4.17763 2.70873L4.67822 2.33914L5.18348 2.70873L4.99635 2.11458L5.49693 1.74499H4.87003L4.67822 1.15552ZM7.31214 1.15552L7.125 1.74499H6.49342L7.00804 2.11458L6.81155 2.70873L7.31214 2.33914L7.82676 2.70873L7.63026 2.11458L8.13085 1.74499H7.51331L7.31214 1.15552ZM9.95541 1.15552L9.75892 1.74499H9.1367L9.64196 2.11458L9.45483 2.70873L9.95541 2.33914L10.4607 2.70873L10.2595 2.11458L10.7741 1.74499H10.1425L9.95541 1.15552Z'
        fill='white'
      />
    </svg>
  );
};
