import posthog from 'posthog-js';

export const configurePosthog = (): void => {
  if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_NODE_ENV == 'production') {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host:
        process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
      person_profiles: 'identified_only',
      // Enable debug mode in development
      loaded: (posthog) => {
        if (process.env.NODE_ENV === 'development') posthog.debug();
      },
    });
  }
};
