export const USRoundFlag = ({ width = 20, height = 20 }): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    data-testid='us-round-flag'
  >
    <g clipPath='url(#clip0_4896_7026)'>
      <path
        d='M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z'
        fill='#F0F0F0'
      />
      <path
        d='M7.69568 8.00005H15C15 7.36824 14.9158 6.75618 14.7589 6.17395H7.69568V8.00005Z'
        fill='#D80027'
      />
      <path
        d='M7.69568 4.34783H13.9726C13.5441 3.64859 12.9962 3.03054 12.3574 2.52173H7.69568V4.34783Z'
        fill='#D80027'
      />
      <path
        d='M8.00003 15C9.64746 15 11.1617 14.4306 12.3574 13.4783H3.64264C4.83838 14.4306 6.3526 15 8.00003 15Z'
        fill='#D80027'
      />
      <path
        d='M2.02742 11.6521H13.9726C14.3166 11.0908 14.5834 10.4772 14.7589 9.82605H1.24115C1.41661 10.4772 1.68341 11.0908 2.02742 11.6521Z'
        fill='#D80027'
      />
      <path
        d='M4.24253 2.09315H4.88043L4.28707 2.52422L4.51373 3.22173L3.92039 2.79066L3.32706 3.22173L3.52284 2.61916C3.00041 3.05434 2.54252 3.56419 2.16517 4.13228H2.36957L1.99187 4.40668C1.93302 4.50484 1.87659 4.60456 1.8225 4.70576L2.00286 5.26087L1.66637 5.01639C1.58272 5.1936 1.50621 5.37481 1.43745 5.55979L1.63615 6.17141H2.36957L1.77621 6.60249L2.00286 7.3L1.40953 6.86893L1.05411 7.12716C1.01854 7.41312 1 7.70439 1 8H8C8 4.13403 8 3.67827 8 1C6.61717 1 5.32811 1.40113 4.24253 2.09315Z'
        fill='#0052B4'
      />
    </g>
    <defs>
      <clipPath id='clip0_4896_7026'>
        <rect width='14' height='14' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>
);
