export const UKRoundFlag = ({ width = 20, height = 20 }): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    data-testid='uk-round-flag'
  >
    <g clipPath='url(#clip0_4896_9324)'>
      <path
        d='M10 19.0909C15.0208 19.0909 19.0909 15.0207 19.0909 9.99997C19.0909 4.9792 15.0208 0.909058 10 0.909058C4.97926 0.909058 0.909119 4.9792 0.909119 9.99997C0.909119 15.0207 4.97926 19.0909 10 19.0909Z'
        fill='#F0F0F0'
      />
      <path
        d='M2.78838 4.46533C2.07428 5.39442 1.53582 6.46526 1.22229 7.62858H5.95162L2.78838 4.46533Z'
        fill='#0052B4'
      />
      <path
        d='M18.7778 7.62854C18.4643 6.46526 17.9258 5.39442 17.2117 4.46533L14.0485 7.62854H18.7778Z'
        fill='#0052B4'
      />
      <path
        d='M1.22229 12.3716C1.53586 13.5349 2.07431 14.6057 2.78838 15.5348L5.95152 12.3716H1.22229Z'
        fill='#0052B4'
      />
      <path
        d='M15.5347 2.78841C14.6056 2.07431 13.5348 1.53586 12.3715 1.22229V5.95159L15.5347 2.78841Z'
        fill='#0052B4'
      />
      <path
        d='M4.46527 17.2116C5.39435 17.9257 6.4652 18.4642 7.62848 18.7777V14.0485L4.46527 17.2116Z'
        fill='#0052B4'
      />
      <path
        d='M7.62845 1.22229C6.46516 1.53586 5.39432 2.07431 4.46527 2.78838L7.62845 5.95155V1.22229Z'
        fill='#0052B4'
      />
      <path
        d='M12.3716 18.7777C13.5349 18.4642 14.6057 17.9257 15.5348 17.2116L12.3716 14.0485V18.7777Z'
        fill='#0052B4'
      />
      <path
        d='M14.0485 12.3716L17.2117 15.5348C17.9258 14.6057 18.4643 13.5349 18.7778 12.3716H14.0485Z'
        fill='#0052B4'
      />
      <path
        d='M19.014 8.81421H11.1859V0.986011C10.7976 0.935478 10.4019 0.909058 10 0.909058C9.59807 0.909058 9.20241 0.935478 8.81427 0.986011V8.81414H0.986072C0.935539 9.20235 0.909119 9.59808 0.909119 9.99997C0.909119 10.4019 0.935539 10.7976 0.986072 11.1857H8.8142V19.0139C9.20241 19.0645 9.59807 19.0909 10 19.0909C10.4019 19.0909 10.7976 19.0645 11.1858 19.0139V11.1858H19.014C19.0645 10.7976 19.0909 10.4019 19.0909 9.99997C19.0909 9.59808 19.0645 9.20235 19.014 8.81421Z'
        fill='#D80027'
      />
    </g>
    <defs>
      <clipPath id='clip0_4896_9324'>
        <rect
          width='18.1818'
          height='18.1818'
          fill='white'
          transform='translate(0.909119 0.909058)'
        />
      </clipPath>
    </defs>
  </svg>
);
