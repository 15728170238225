export const buildRange = (end: number, start = 1, step = 1): string[] => {
  const range = [] as string[];
  for (let i = start; i <= end; i += step) {
    range.push(i.toString());
  }
  return range;
};

export const ADULT_HAT_SIZE_OPTIONS = [
  '20 3/4',
  '21',
  '21 1/2',
  '22',
  '22 1/2',
  '22 3/4',
  '23',
  '23 1/2',
  '24',
  '24 3/8',
  '24 7/8',
  '25 1/8',
  '26 1/2',
];

export const MINOR_HAT_SIZE_OPTIONS = [
  '19 1/8',
  '19 1/2',
  '19 3/4',
  '20 1/4',
  '20 3/4',
  '21 1/8',
  '21 1/2',
  '21 7/8',
];

export const ALL_HAT_SIZE_OPTIONS = [
  '19 1/8',
  '19 1/2',
  '19 3/4',
  '20 1/4',
  '20 3/4',
  '21',
  '21 1/8',
  '21 1/2',
  '21 7/8',
  '22',
  '22 1/2',
  '22 3/4',
  '23',
  '23 1/2',
  '24',
  '24 3/8',
  '24 7/8',
  '25 1/8',
  '26 1/2',
];

export const ALL_HEAD_SIZE_OPTIONS = [
  '19 1/8',
  '19 1/2',
  '19 3/4',
  '20 1/4',
  '20 3/4',
  '21',
  '21 1/8',
  '21 1/2',
  '21 7/8',
  '22',
  '22 1/2',
  '22 3/4',
  '23',
  '23 1/2',
  '24',
  '24 3/8',
  '24 7/8',
  '25 1/8',
  '26 1/2',
];

export const ALL_HAT_SIZE_SORT_ORDER_MAP = {
  '19 1/8': 1,
  '19 1/2': 2,
  '19 3/4': 3,
  '20 1/4': 4,
  '20 3/4': 5,
  '21': 6,
  '21 1/8': 7,
  '21 1/2': 8,
  '21 7/8': 9,
  '22': 10,
  '22 1/2': 11,
  '22 3/4': 12,
  '23': 13,
  '23 1/2': 14,
  '24': 15,
  '24 3/8': 16,
  '24 7/8': 17,
  '25 1/8': 18,
  '26 1/2': 19,
};

export const SHIRT_SIZE_OPTIONS = ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'];

// SHIRT_SIZE_SORT_ORDER_MAP also exists on casting-galleries service
export const SHIRT_SIZE_SORT_ORDER_MAP = {
  XS: 1,
  S: 2,
  M: 3,
  L: 4,
  XL: 5,
  XXL: 6,
  XXXL: 7,
};

export const DRESS_SIZE_OPTIONS = ['00', ...buildRange(30, 0, 2)]; // goes from 00 -> 30

export const CUP_SIZE_OPTIONS = [
  'AA',
  'A',
  'B',
  'C',
  'D',
  'DD', // same as E
  'DDD',
  'F',
  'G',
  'H',
  'I',
  'J',
];

// CUP_SIZE_SORT_ORDER_MAP also exists on casting-galleries service
export const CUP_SIZE_SORT_ORDER_MAP = {
  AA: 1,
  A: 2,
  B: 3,
  C: 4,
  D: 5,
  DD: 6, // same as E
  DDD: 7,
  F: 8,
  G: 9,
  H: 10,
  I: 11,
  J: 12,
};

export const UK_DRESS_SIZE_OPTIONS = buildRange(26, 4, 2);
