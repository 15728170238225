import type { SVGProps } from 'react';

export const UKFlag = ({
  width = 24,
  height = 16,
  className = '',
  stroke = 'currentColor',
  strokeWidth = 1.5,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox='0 0 24 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M0 0H24V16.0002H0V0Z' fill='white' />
      <path
        d='M13.5 -0.000244141H10.5V6.49973H0V9.49973H10.5V15.9997H13.5V9.49973H24V6.49973H13.5V-0.000244141Z'
        fill='#D80027'
      />
      <path
        d='M18.4589 10.7825L24.0002 13.861V10.7825H18.4589ZM14.6089 10.7825L24.0002 15.9999V14.5245L17.2646 10.7825H14.6089ZM21.4987 15.9999L14.6089 12.1718V15.9999H21.4987Z'
        fill='#0052B4'
      />
      <path
        d='M14.6089 10.7825L24.0002 15.9998V14.5245L17.2646 10.7825H14.6089Z'
        fill='white'
      />
      <path
        d='M14.6089 10.7825L24.0002 15.9998V14.5245L17.2646 10.7825H14.6089Z'
        fill='#D80027'
      />
      <path
        d='M4.23474 10.7822L0 13.1349V10.7822H4.23474ZM9.39132 11.4457V15.9997H1.19489L9.39132 11.4457Z'
        fill='#0052B4'
      />
      <path
        d='M6.73562 10.7825L0 14.5245V15.9998L9.39132 10.7825H6.73562Z'
        fill='#D80027'
      />
      <path
        d='M5.54133 5.21714L0 2.13857V5.21714H5.54133ZM9.39132 5.21714L0 -0.000244141V1.4751L6.73562 5.21714H9.39132ZM2.50153 -0.000244141L9.39132 3.82776V-0.000244141H2.50153Z'
        fill='#0052B4'
      />
      <path
        d='M9.39132 5.21714L0 -0.000244141V1.4751L6.73562 5.21714H9.39132Z'
        fill='white'
      />
      <path
        d='M9.39132 5.21714L0 -0.000244141V1.4751L6.73562 5.21714H9.39132Z'
        fill='#D80027'
      />
      <path
        d='M19.7655 5.21743L24.0002 2.86477V5.21743H19.7655ZM14.6089 4.55396V0H22.8053L14.6089 4.55396Z'
        fill='#0052B4'
      />
      <path
        d='M17.2646 5.21714L24.0002 1.4751V-0.000244141L14.6089 5.21714H17.2646Z'
        fill='#D80027'
      />
    </svg>
  );
};
