export const UK_PROJECTS_ENABLED =
  process.env.NEXT_PUBLIC_UK_PROJECTS_ENABLED === 'true';
import { RATE_NOTE_LABEL, RATE_TYPE_LABEL } from '@lib/constants';

export const TIMEZONE_MAP = {
  'America/Los_Angeles': 'PST (GMT -8:00)',
  'America/Denver': 'MST (GMT -7:00)',
  'America/Chicago': 'CST (GMT -6:00)',
  'America/New_York': 'EST (GMT -5:00)',
  'America/Anchorage': 'AKST (GMT -9:00)',
  'Europe/London': 'GMT (GMT +0:00)',
};

export const TIMEZONE_REGION_MAP = {
  'America/Los_Angeles': 'Pacific',
  'America/Denver': 'Mountain',
  'America/Chicago': 'Central',
  'America/New_York': 'Eastern',
  'America/Anchorage': 'Alaska',
  'Pacific/Honolulu': 'Hawaii-Aleutian',
};

export const DEFAULT_UNIT_OPTIONS = [
  'Main',
  'Covid',
  '2nd Unit',
  'Fitting',
  'Interview',
  'Stills',
];

export const ALL_DEFAULT_UNIT_FORMATS = [
  'Main',
  'Covid',
  '2nd',
  '2nd Unit',
  'Fitting',
  'Interview',
  'Stills',
];

export const UNITS_ORDER_MAP = {
  Main: 0,
  '2nd': 1,
  '2nd Unit': 1,
  Fitting: 2,
  Interview: 3,
  Stills: 4,
  Custom: 5,
  Covid: 6,
};

export const BODY_TYPE_OPTIONS = [
  'Average',
  'Slim',
  'Curvy',
  'Athletic/Toned',
  'Muscular',
  'Heavyset/Stocky',
  'Plus sized',
  'Little Person',
];
export const HAIR_LENGTH_OPTIONS = [
  'Bald',
  'Balding',
  'Buzz Cut',
  'Short',
  'Medium',
  'Long',
  'Pixie Cut',
];
export const EYE_COLOR_OPTIONS = [
  'Brown',
  'Blue',
  'Green',
  'Hazel',
  'Amber',
  'Grey',
  'Red',
  'Violet',
];
export const HAIR_COLOR_OPTIONS = [
  'Black',
  'Brown',
  'Blonde',
  'Red',
  'Grey',
  'White',
  'Bald',
  'Other',
];

export const DAYS_OF_THE_WEEK = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
] as const;

export const RATE_TYPE_MAP = {
  'pact/faa': 'PACT/FAA',
  'pact/equity': 'PACT/Equity',
  'bbc/equity': 'BBC/Equity',
  commercial: 'Commercial',
  non_union: 'Non-union',
  unscripted: 'Unscripted',
  other: 'Other',
};

export const DEFAULT_WORK_WEEK = DAYS_OF_THE_WEEK.slice(1, 6);

export type DayOfTheWeek = typeof DAYS_OF_THE_WEEK[number];

export const BUMP_TYPE_MAP = {
  'COVID Test': { category: 'COVID Test', subcategory: undefined }, // custom bump (no default vals)
  'Wardrobe Change': {
    category: 'Wardrobe',
    subcategory: 'Wardrobe Change',
  },
  'Wet Work': { category: 'Wet Work', subcategory: undefined },
  'Smoke Work': { category: 'Smoke Work', subcategory: undefined },
  Mileage: { category: 'Mileage', subcategory: undefined },
  'Off Camera Dialogue': {
    category: 'Off Camera Dialogue',
    subcategory: undefined,
  },
  'Catastrophe Pay': { category: 'Catastrophe Pay', subcategory: undefined },
  'N/U Fitting': { category: 'N/U Fitting', subcategory: undefined },
  'SAG Late Fee': { category: 'SAG Late Fee', subcategory: undefined }, // custom bump (no default vals)
  Auto: { category: 'Transportation', subcategory: 'Auto' },
  'Body Makeup/Haircut': {
    category: 'Body Makeup/Haircut',
    subcategory: undefined,
  },
  Bicycle: {
    category: 'Transportation',
    subcategory: 'Bicycle',
  },
  Camera: { category: 'Props', subcategory: 'Camera' },
  'Golf Clubs': { category: 'Props', subcategory: 'Golf Clubs' },

  'Formal Attire': {
    category: 'Wardrobe',
    subcategory: 'Formal Attire',
  },
  Luggage: { category: 'Props', subcategory: 'Luggage' },
  Moped: {
    category: 'Transportation',
    subcategory: 'Moped',
  },
  Motorcycle: {
    category: 'Transportation',
    subcategory: 'Motorcycle',
  },
  Pet: { category: 'Props', subcategory: 'Pet' },
  'Police Motorcycle': {
    category: 'Transportation',
    subcategory: 'Police Motorcycle',
  },
  'Police Uniform': {
    category: 'Wardrobe',
    subcategory: 'Police Uniform',
  },

  'Skates/Skateboards': {
    category: 'Transportation',
    subcategory: 'Skates/Skateboards',
  },
  'Skis & Poles': { category: 'Props', subcategory: 'Skis & Poles' },

  'Tennis Racquet': {
    category: 'Props',
    subcategory: 'Tennis Racquet',
  },
  Trailer: {
    category: 'Transportation',
    subcategory: 'Trailer',
  },
  'Wet Suit': { category: 'Wardrobe', subcategory: 'Wet Suit' },
};

export const BUMP_MINIMIZED_LABELS = {
  'Wardrobe Change': 'Wardrobe',
  'Formal Attire': 'Formal',
  'Police Uniform': 'Police',
  'Wet Suit': 'Wet Suit',
  Pet: 'Pet',
  'Golf Clubs': 'Golf',
  'Tennis Racquet': 'Tennis',
  Luggage: 'Luggage',
  Camera: 'Camera',
  'Skis & Poles': 'Skis',
  Auto: 'Auto',
  Bicycle: 'Bike',
  'Police Motorcycle': 'Motorcycle',
  Motorcycle: 'Motorcycle',
  Moped: 'Moped ',
  Trailer: 'Trailer',
  'Skates/Skateboards': 'Skates',
  'Wet Work': 'Wet',
  'Smoke Work': 'Smoke',
  'Body Makeup/Haircut': 'Haircut',
  Mileage: 'Miles',
  'N/U Fitting': 'N/U Fitting',
  'Off Camera Dialogue': 'OCD',
  'Catastrophe Pay': 'Catastrophe Pay',
};

export const SORTABLE_LIST_COLUMNS = [
  'Status',
  'Role',
  'Type',
  'First Name',
  'Last Name',
  'Union',
  'Gender',
  'Ethnicity',
  'Age Range',
  'Minors Age',
  'Age',
  'Service',
  'Rate',
  'Notes',
  RATE_TYPE_LABEL,
  RATE_NOTE_LABEL,
  'SR/Van',
  'Height',
  'Weight',
  'Chest',
  'Dress',
  'Waist',
  'Hip',
  'Hat',
  'Neck',
  'Shirt',
  'Sleeve',
  'Pants',
  'Inseam',
  'Shoe',
  'Bra',
  'Selects',
  'I-9',
  'Dress (UK)',
  'Collar',
  'Chest/Bust',
  'Cup',
  'Natural Waist',
  'Trouser Waist',
  'Hips',
  'Inside Leg',
  'Head',
  'Shoe (UK)',
];

export const UK_LICENSE_TYPES = [
  'Full Car',
  'Advanced Car',
  'Driving Instructor',
  'LGV (HGV) Class 1',
  'LGV (HGV) Class 2',
  'Motorcycle Under 125cc',
  'Motorcycle Above 125cc',
  'PCV (PSV) Coach',
  'PCV (PSV) Minibus',
  'Taxi',
];

export const UK_LICENSE_OPTIONS = UK_LICENSE_TYPES.map((license) => ({
  label: license,
  value: license,
}));

export const POC_DOCUMENT_TYPES = [
  {
    value: 'uk_birth_or_adoption_certificate',
    label: 'UK Birth or Adoption Certificate',
  },
  {
    value: 'irish_birth_or_adoption_certificate',
    label: 'Irish Birth or Adoption Certificate',
  },
  {
    value: 'british_naturalisation_certificate',
    label: 'Certificate of Registration of Naturalisation as a British citizen',
  },
];