export const VEHICLE_TYPE_OPTIONS = [
  'Convertible',
  'SUV',
  'Sedan',
  'Crossover',
  'Station Wagon',
  'Van',
  'Sports',
  'Truck',
  'Motorcycle',
  'Other',
];
export const VEHICLE_COLOR_OPTIONS = [
  'White',
  'Black',
  'Grey',
  'Silver',
  'Gold',
  'Tan',
  'Brown',
  'Burgundy',
  'Red',
  'Orange',
  'Yellow',
  'Green',
  'Blue',
  'Purple',
];
export const VEHICLE_MAKE_OPTIONS = [
  'Acura',
  'Aston Martin',
  'Audi',
  'BMX',
  'Bentley',
  'Buick',
  'Cadillac',
  'Chevrolet',
  'Chrysler',
  'Dodge',
  'Ferrari',
  'Fiat',
  'Ford',
  'GMC',
  'Honda',
  'Hummer',
  'Hyundai',
  'Infiniti',
  'Jaguar',
  'Jeep',
  'Kia',
  'Lamborghini',
  'Lexus',
  'Lincoln',
  'MINI',
  'Maserati',
  'Mazda',
  'Mercedes-Benz',
  'Mitsubishi',
  'Nissan',
  'Oldsmobile',
  'Plymouth',
  'Pontiac',
  'Porsche',
  'Ram',
  'Rolls-Royce',
  'Saturn',
  'Scion',
  'Subaru',
  'Tesla',
  'Toyota',
  'Volkswagon',
  'Volvo',
];

export const VEHICLE_MAKE_UK_OPTIONS = [
  'Abarth',
  'AC',
  'Aixam',
  'Alfa Romeo',
  'Alpine',
  'Asia',
  'Aston Martin',
  'Audi',
  'Austin',
  'BAC',
  'Bentley',
  'BMW',
  'BMW Alpina',
  'Bristol',
  'Cadillac',
  'Caterham',
  'Chevrolet',
  'Chrysler',
  'Citroën',
  'Coleman Milne',
  'Corvette',
  'Cupra',
  'Dacia',
  'Daewoo',
  'Daihatsu',
  'Daimler',
  'De Tomaso',
  'DFSK',
  'Dodge',
  'DS',
  'Eagle',
  'Farbio',
  'FBS',
  'Ferrari',
  'Fiat',
  'Ford',
  'FSO',
  'Genesis',
  'GWM ORA',
  'Honda',
  'Hummer',
  'Hyundai',
  'INEOS',
  'Infiniti',
  'Invicta',
  'Isuzu',
  'Jaguar',
  'Jeep',
  'Jensen',
  'Kia',
  'KTM',
  'Lada',
  'Lamborghini',
  'Lancia',
  'Land Rover',
  'LEVC',
  'Lexus',
  'Ligier',
  'Lotus',
  'LTI',
  'MAHINDRA',
  'Marcos',
  'Marlin',
  'Maserati',
  'MAXUS',
  'Maybach',
  'Mazda',
  'McLaren',
  'Mercedes-AMG',
  'Mercedes-Benz',
  'MG',
  'MG Motor UK',
  'MIA',
  'Microcar',
  'MINI',
  'Mitsubishi',
  'MOKE',
  'Morgan',
  'Nissan',
  'Noble',
  'Opel',
  'Perodua',
  'Peugeot',
  'PGO',
  'Polestar',
  'Porsche',
  'PRINDIVILLE',
  'Proton',
  'Reliant',
  'Renault',
  'Rolls-Royce',
  'Rover',
  'Saab',
  'San',
  'SAO',
  'SEAT',
  'Skoda',
  'Smart',
  'SsangYong',
  'Subaru',
  'Suzuki',
  'Talbot',
  'Tata',
  'TD Cars',
  'Tesla',
  'Toyota',
  'TVR',
  'Vauxhall',
  'Volkswagen',
  'Volvo',
  'Westfield',
  'Yugo',
];

export const VEHICLE_CONDITION_OPTIONS = [
  'Excellent / New',
  'Good / Used',
  'Poor / Visible Damages',
];

export const UK_LICENSE_TYPE_OPTIONS = [
  'Full Car',
  'Advanced Car',
  'Driving Instructor',
  'LGV (HGV) Class 1',
  'LGV (HGV) Class 2',
  'Motorcycle Under 125cc',
  'Motorcycle Above 125cc',
  'PCV (PSV) Coach',
  'PCV (PSV) Minibus',
  'Taxi',
];
