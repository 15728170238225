import { ListUser } from '@views/lists/types/list-user';
import { ApiNames, ApiProxy, ApiRequest, HttpMethod } from '../../apiProxy';

export class CastingListsCommandApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.Lists;

  public static copyMembersToList(
    castingListId: number,
    copyMembersToListDto: CopyMembersToListDto
  ): Promise<null> {
    const endpoint = `/projectLists/${castingListId}/copyToList`;
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        endpoint,
        HttpMethod.POST,
        copyMembersToListDto
      )
    );
  }

  public static updateListMembers(
    castingListId: number,
    updateListMemberDtos: UpdateCastingListMember[],
    notificationPreferences?: NotificationPreferences
  ): Promise<BaseListUser[]> {
    const endpoint = `/projectLists/${castingListId}/members`;
    const body = {
      updates: updateListMemberDtos,
    };
    if (notificationPreferences) {
      Object.assign(body, { notificationPreferences });
    }
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, body)
    );
  }

  public static updateListMembersBulk(
    castingListId: number,
    listMemberIds: number[],
    castingListMemberUpdate: UpdateCastingListMemberBulk,
    notificationPreferences?: NotificationPreferences
  ): Promise<BaseListUser[]> {
    const endpoint = `/projectLists/${castingListId}/members?update=bulk&list_membership_ids=[${listMemberIds}]`;
    const body = {
      update: castingListMemberUpdate, // backend model looks for singular "update" key
    };
    if (notificationPreferences) {
      Object.assign(body, { notificationPreferences });
    }
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, body)
    );
  }

  public static updateCastingList(
    castingListId: number,
    castingListUpdate: UpdateCastingList
  ): Promise<CastingList> {
    const endpoint = `/projectLists/${castingListId}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, castingListUpdate)
    );
  }

  public static archiveCastingList(
    castingListId: number,
    is_archived = true
  ): Promise<CastingList> {
    const endpoint = `/projectLists/${castingListId}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, { is_archived })
    );
  }

  public static deleteCastingLists(
    castingListIds: number[]
  ): Promise<DeleteCastingListsResponse> {
    const endpoint = `/projectLists?casting_list_ids=[${castingListIds}]`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE)
    );
  }

  public static createCastingLists(
    castingLists: CreateCastingList[]
  ): Promise<CastingList[]> {
    const endpoint = `/projectLists`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, castingLists)
    );
  }

  public static addMembersToCastingList(
    castingListId: number,
    members: CreateListUser[],
    applicationId?: number,
    notificationPreferences?: NotificationPreferences
  ): Promise<FullListUser[]> {
    let endpoint = `/projectLists/${castingListId}/members`;

    if (applicationId) {
      endpoint += `?applicationId=${applicationId}`;
    }
    const body = {
      users: members,
    };
    if (notificationPreferences) {
      Object.assign(body, { notificationPreferences });
    }
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, body)
    );
  }

  public static deleteListMembers(
    listMembershipIds: number[],
    projectId: number
  ): Promise<UnitDates[]> {
    const endpoint = `/projectLists/members?list_membership_ids=[${listMembershipIds}]&project_id=${projectId}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE)
    );
  }

  public static updateCastingListValues(
    castingListId: number,
    listValueDtos: UpdateCastingListValue[]
  ): Promise<CastingListValue[]> {
    const endpoint = `/projectLists/${castingListId}/values`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, listValueDtos)
    );
  }

  public static updateUnitDates(
    castingListId: number,
    unitDatesDtos: UpdateUnitDates[] // must have unit_ids and list_membership_ids
  ): Promise<UnitDates[]> {
    const endpoint = `/projectLists/${castingListId}/members/unitDates`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, unitDatesDtos)
    );
  }

  public static createCastingListColumns(
    castingListId: number,
    listColumnDtos: CreateCastingListColumn[]
  ): Promise<CastingListColumn[]> {
    const endpoint = `/projectLists/${castingListId}/columns`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, listColumnDtos)
    );
  }

  public static updateCastingListColumns(
    castingListId: number,
    updateListColumnDtos: UpdateCastingListColumn[]
  ): Promise<CastingListColumn[]> {
    const endpoint = `/projectLists/${castingListId}/columns`;
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        endpoint,
        HttpMethod.PUT,
        updateListColumnDtos
      )
    );
  }

  public static deleteCastingListColumns(
    castingListId: number,
    deleteListColumnsIds: number[]
  ): Promise<CastingListColumn[]> {
    const endpoint = `/projectLists/${castingListId}/columns?list_column_ids=[${deleteListColumnsIds}]`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE)
    );
  }

  public static upsertListFilterSettings(
    castingListId: number,
    dto: UpdateListFilterSettings
  ): Promise<ListFilterSettings> {
    const endpoint = `/projectLists/${castingListId}/filterSettings`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, dto)
    );
  }

  public static deleteListFilterSettings(
    castingListId: number
  ): Promise<DeletedListTableSettings> {
    const endpoint = `/projectLists/${castingListId}/filterSettings`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE)
    );
  }

  public static upsertListSortSettings(
    castingListId: number,
    dto: UpsertListSortSettingsDto
  ): Promise<ListSortSettings> {
    const endpoint = `/projectLists/${castingListId}/sortSettings`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, dto)
    );
  }

  public static deleteListSortSettings(
    castingListId: number
  ): Promise<DeletedListTableSettings> {
    const endpoint = `/projectLists/${castingListId}/sortSettings`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE)
    );
  }

  public static sendMiscMessage(
    castingListId: number,
    dto: SendMiscMessageDto
  ): Promise<SendMiscResponse> {
    const endpoint = `/projectLists/${castingListId}/members/sendMisc`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, dto)
    );
  }

  public static importMembersToList(
    castingListId: number,
    dtos: ListImportMember[]
  ): Promise<void> {
    const endpoint = `/projectLists/${castingListId}/importMembersToList`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, dtos)
    );
  }

  public static updateListUserPhotosPositions(
    castingListId: number,
    dtos: UpsertListMemberPhotos[]
  ): Promise<ListUserPhoto[]> {
    const endpoint = `/projectLists/${castingListId}/members/photos`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, dtos)
    );
  }

  public static directAddMembersToLists(
    castingListId: number,
    directAddMembersDtos: ListDirectAddMembersDto[],
    signupProperties?: CreateUserSignupProperties
  ): Promise<ListUser[]> {
    const endpoint = `/projectLists/${castingListId}/directAddMembers`;
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        endpoint,
        HttpMethod.POST,
        {
          members: directAddMembersDtos,
          signupProperties,
        }
      )
    );
  }

  public static linkUserToList(
    castingListId: number,
    dto: LinkListUserDto
  ): Promise<LinkListUserResponse> {
    const endpoint = `/projectLists/${castingListId}/linkUser`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, dto)
    );
  }
  public static mergeColumns(
    castingListId: number,
    mergeColumnsDto: MergeListColumnsDto
  ): Promise<CastingListColumn> {
    const endpoint = `/projectLists/${castingListId}/columns/merge`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, mergeColumnsDto)
    );
  }
}
